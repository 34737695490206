.divider {
    border: 2px solid #e3e5ea;
}

p {
    color: #425a69;
    line-height: 140%;
}

.leftTime {
    color: #e55959;
}

span {
    color: var(--text-primary, #364954);
    line-height: 140%;
}

.wrapperTooltip.wrapperTooltip {
    background: #e55959;
    border-radius: 12px;
    z-index: 1;
    @media screen and (max-width: 768px) {
        width: 170px;
    }
}

.tooltip {
    color: var(--color-white-main, #fff);
    line-height: 18px;
}
