.assessmentThem {
    border: 2px solid var(--color-blue-300, #d1eefd);
    background: var(--color-blue-100, #e4f3fb);
}

.successSectionAssessment {
    border: 2px solid var(--color-green-300, #cee8c2);
    background: var(--color-green-100, #ecfce4);
}

.failureSectionAssessment {
    border: 2px solid var(--color-red-300, #f8caca);
    background: var(--color-red-100, #ffefef);
}

.notAvailableAssessment {
    background: #fff;
    border: 2px solid var(--color-black-200, #cbdce7);
}

.sectionAssessmentLink {
    background: #55bbeb !important;
    box-shadow: 0px 4px 0px 0px #0b638c !important;
    color: #fff;
    font-style: normal;
    line-height: 16px;
    transition: 0.2s;
    &:hover {
        background: var(--background-accent-active, #36a0d0) !important;
        box-shadow: 0px 4px 0px 0px #08425c !important;
    }
    &:active {
        box-shadow: none !important;
        transform: translateY(4px);
    }
    &.disable {
        background: var(--color-black-300, #a3c0d2);
        box-shadow: 0px 3.97241px 0px 0px #577487;
    }
    box-shadow: 0px 4px 0px 0px #577487;
}

.assessmentTitle {
    color: var(--Gray-2, #4f4f4f);
    line-height: normal;
}

.assessmentDescription {
    color: var(--color-black-500, #678aa1);
    line-height: normal;
}

.descriptionSuccess {
    color: var(--color-green-800, #65a845);
    font-weight: 600;
}

.descriptionFail {
    color: var(--color-red-500, #e55959);
    font-weight: 600;
}

.wrapperTooltip.wrapperTooltip {
    background-color: #364954;
    color: #fff;
    border-radius: 12px;
    padding: 8px 12px;
    max-width: 320px;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    z-index: 200;
}
