.wrapper {
    border-color: #cbdce7;

    &.active {
        border-color: #55bbeb;
    }
}

.nameTopic {
    color: #4f4f4f;
}

.percentage {
    color: #678aa1;
}

.wrapperTooltip.wrapperTooltip {
    background-color: #364954;
    color: #fff;
    border-radius: 12px;
    padding: 8px 12px;
    max-width: 320px;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    z-index: 200;
}

@keyframes floatAnimation {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0);
    }
}

.tooltip {
    background: #65a845;
    color: #fff;
    line-height: 18px;
    animation: floatAnimation 1s ease-in-out infinite;
    &::after {
        content: '';
        position: absolute;
        width: 18px;
        height: 18px;
        top: -7px;
        left: 50%;
        margin-inline-start: -9px;
        background-image: url('../../../../../public/icons/tooltip-arrow.svg');
        background-repeat: no-repeat;
    }
}
