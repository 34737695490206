.activeWrapper {
    &::after {
        content: '';
        width: 100%;
        height: 2px;
        position: absolute;
        bottom: 0;
        background-color: #fff;
        background: var(--color-blue-800, #36a0d0);
        border-radius: 2px 2px 0px 0px;
    }
}

.name {
    &:not(.active) {
        color: #678aa1;
    }

    &.active {
        color: var(--color-blue-800, #36a0d0);
    }
}
.borderSubject {
    border: 2px solid #cbdce7;
}
.borderFeed {
    border: 2px solid #55bbeb;
}
