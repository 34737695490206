.input {
    border: 2px solid var(--Input-stroke, #d2dfe4);
    &:focus {
        border: 2px solid var(--Input-stroke-focus, #678aa1);
    }
}
.inputError {
    border: 2px solid var(--color-red-500, #e55959);
}
.inputSuccess {
    border: 2px solid var(--color-blue-main, #55bbeb);
}
.word {
    color: #678aa1;
}
.successIcon {
    background-image: url('../../../../public/icons/checkBox.svg');
    background-position: calc(100% - 20px) center;
    background-repeat: no-repeat;
    background-size: 20px;
}
.errorMessage {
    color: var(--color-red-500, #e55959);
}
