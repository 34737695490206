.subTitle {
    color: #678aa1;
}
.button {
    border-color: var(--Stroke, #e3e5ea);
}
.exit {
    color: #678aa1;
}

.filter {
    border-color: #a3c0d2;

    &:hover {
        background-color: #e9f0f3;
    }
}

.closeButon {
    background-color: #30404a;
}

.nav {
    span {
        background-repeat: no-repeat;
        background-size: 24px 24px;
        background-position: 10px center;
        color: #678aa1;
    }

    .linkFavorites {
        background-image: url('../../../../../public/icons/bookmarkMenu.svg');
    }

    .linkFeed {
        background-image: url('../../../../../public/icons/feed.svg');
    }

    [class='active'] {
        background-color: transparent;
        span {
            color: #55bbeb;
        }
        .linkFeed {
            background-image: url('../../../../../public/icons/feedActive.svg');
        }

        .linkFavorites {
            background-image: url('../../../../../public/icons/bookmarkMenuActive.svg');
        }
    }
}
