.wrapper {
    background-color: #fff;
}

.overlay {
    background: rgba(31, 41, 55, 0.9);
}

.close {
    background-color: #fff;
}
.emptyPhoto {
    border-radius: var(--radius-all-radius, 16px);
    background: #e9f0f3;
    height: 268px;
    width: 268px;
}

.more {
    color: #55bbeb;
}

.shadow {
    background: linear-gradient(
        180deg,
        rgba(2, 0, 36, 0) 0%,
        rgba(42, 121, 9, 0) 50%,
        rgba(255, 255, 255, 1) 100%
    );
}

.overlay {
    background-color: #000;
    opacity: 0;
    &:hover {
        opacity: 0.3;
    }
}

.count {
    background: var(--color-black-500, #577487);
}

.content {
    background-color: #fff;
    div div div div p {
        text-indent: 0em !important;
        font-family: Inter, sans-serif !important;
        color: black !important;
    }
    div div div div p span img {
        display: inline-block !important;
    }

    div div div div p span div {
        display: inline !important;
        font-family: Inter, sans-serif !important;
        color: black !important;
    }
    div div div div p span {
        font-family: Inter, sans-serif !important;
        color: black !important;
    }
    div div div div p strong {
        font-family: Inter, sans-serif !important;
        color: black !important;
    }

    div div div th p {
        text-indent: 0 !important;
        font-family: Inter, sans-serif !important;
        color: black !important;
    }
    div div div td p {
        text-indent: 0 !important;
        font-family: Inter, sans-serif !important;
        color: black !important;
    }
    div div div div ol li span div {
        width: fit-content !important;
        display: inline !important;
    }
}
