.button {
  font-size: 16px;
  font-weight: 500;
  padding: 9px 16px;
  border-radius: 12px;
  color: #678aa1;
  background-color: #fff;
}

.red {
  color: #ffffff;
  background-color: #f40101;
}

.blue {
  color: #ffffff;
  background-color: #55bbeb;
}

.green {
  color: #ffffff;
  background-color: #71bd4e;
}
