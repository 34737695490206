.wrapperTooltip.wrapperTooltip {
    background-color: transparent;
    padding: 0;
}

.learn {
    color: #577487;
}
.wrapperButton {
    background-color: #e9f0f3;
}
.wrapperLearn {
    box-shadow: 0px 0px 34px 0px rgba(103, 138, 161, 0.2);
}
