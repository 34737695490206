.leftPart {
    border: 2px solid var(--Stroke, #e3e5ea);
    transition: 0.2s;
    &:hover {
        background: #f2f6f8;
    }
}

.rightPart {
    border: 2.5px solid var(--Stroke, #e3e5ea);
    &:hover {
        background: #f2f6f8;
    }
}

.active {
    background-color: #55bbeb;
    border: none;
    &:hover {
        background-color: #55bbeb;
    }
}

.openNavigation {
    background: #a3c0d2;
    box-shadow: 0px 4px 34px 0px rgba(54, 73, 84, 0.1);
    .numbers {
        color: #364954;
        line-height: 140%;
    }
}

.header {
    background: #fff;
}

.title {
    color: #364954;
    line-height: 140%;
}

.item {
    background: #fff;
    &:hover {
        background: #f2f6f8;
    }
    span {
        line-height: 140%;
    }
}

.colorWhite {
    color: #fff;
}

.tooltipNavigation.tooltipNavigation {
   
    background-color: #364954;
    z-index: 200;
    color: #fff;
}
.navigation.navigation{
    max-width: 392px;
    width: 100%;
    padding: 0;
    border-radius: 16px;
    overflow: hidden;
    z-index: 10;
    @media screen and (max-width: 767px) {
        max-width: 300px;
        
    }
}