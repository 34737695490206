.input {
  border: 1px solid var(--Input-stroke, #d2dfe4);
  &:focus {
    border: 1px solid var(--Input-stroke-focus, #678aa1);
  }
}
.inputError {
  border: 2px solid var(--color-red-500, #e55959);
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
}
.inputSuccess {
  border: 2px solid var(--color-blue-main, #55bbeb);
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
}
.word {
  color: #678aa1;
}
