.wrapperSlider {
    height: 100%;
}

.subtitle {
    color: var(--text-text-secondary, #6d828e);
}
.forgetPasword {
    color: #55bbeb;
}

.enter {
    background: var(--surface-surface-primary, #e9f0f3);

    &:hover {
        background: #e9f0f3;
        color: #55bbeb;
    }
}

.ad {
    border: 1px solid var(--color-gray-200, #cad7df);
}

.footer {
    span {
        color: var(--text-text-tertiary, #9aaebb);
    }
}

.slideBlock {
    display: block;
}

@media screen and (max-width: 900px) {
    .slideBlock {
        display: none;
    }
}
.modalButton {
    background-color: #55bbeb;
    &:hover {
        background-color: #36a0d0;
    }
}
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}
.spinner {
    border: solid 3px #878c97;
    &:hover {
        border: solid 3px #55bbeb;
        background-color: transparent;
    }
    border-right-color: transparent;
    border-bottom-color: transparent;
    transition: all 0.5s ease-in;
    animation-name: rotate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
.validated.validated {
    background: #55bbeb;
    color: #fff;
}

.red {
    background-color: #f24f1c;
}

.green {
    background-color: #80bb00;
}

.blue {
    background-color: #00a5ef;
}

.yellow {
    background-color: #ffba00;
}
