.container.container {
    width: 100%;
    &.size {
        &-sm {
            height: 40px;
        }

        &-xs {
            height: 30px;

            input {
                margin: 0 !important;
            }
            div[data-value] {
                margin: 0 !important;
            }
        }
    }
}
.widthFitContent.widthFitContent {
    width: fit-content;
}

.control.control {
    padding: 0 8px;
    &.size {
        &-xs {
            min-height: 30px;

            &.border {
                border: 1px solid;
                border-color: #b6d3e4;
                box-shadow: 0 0 0 1px transparent;
                border-radius: 8px;
            }
        }

        &-sm {
            min-height: 40px;
        }
        &-md {
            min-height: 48px;
        }
        &-xl {
            min-height: 56px;
        }
    }

    &.border {
        border: 1px solid;
        border-color: #b6d3e4;
        box-shadow: 0 0 0 1px transparent;
        border-radius: 16px;
        &:hover {
            border-color: #678aa1;
        }
        &-focused {
            border-color: #5d7380;
            border-width: 1px;

            &:hover {
                box-shadow: none;
                border-color: #495e6b;
            }
        }

        &-error {
            border-color: #ed1e1e;
        }
    }

    &.noBorder {
        border: 0;
    }
}

.placeholder.placeholder {
    color: #a3c0d2;
    font-weight: 500;
    &.size {
        &-sm {
            font-size: 14px;
        }

        &-sm {
            font-size: 14px;
        }
        &-md {
            font-size: 16px;
        }
        &-xl {
            font-size: 14px;
        }
    }
}

.valueContainer.valueContainer {
    padding: 0;
}

.indicatorSeparator.indicatorSeparator {
    display: none;
}

.indicatorsContainer {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.prefix {
    color: #678aa1;
}

.singleValue.singleValue {
    font-size: 14px;
    font-weight: 500;
    color: #364954;
}

.option.option {
    border-bottom: 1px solid #a3c0d2;
    block-size: fit;
    &:hover {
        background-color: #e9f0f3;
    }
    &:last-of-type {
        border-bottom: none;
    }
    &.selected {
        background-color: #55bbeb;
        &:hover {
            background-color: #65c7f4;
        }
    }
}

.menu.menu {
    border: 1px solid #a3c0d2;
    border-radius: 8px;
    width: fit-content;
    overflow: hidden;
}

.menuList.menuList {
    padding: 0 0;
    &:hover {
        background-color: white;
    }
}
.multiValue.multiValue {
    border-radius: 7px;
    background: var(--color-black-100, #e9f0f3);
}
