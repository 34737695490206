.wrapper {
    background-color: #e9f0f3;
}

.wrapperSubjectFilter {
    background: rgb(233, 240, 243);
    background: linear-gradient(
        180deg,
        rgba(233, 240, 243, 1) 0%,
        rgba(233, 240, 243, 1) 83%,
        rgba(0, 212, 255, 0) 85%
    );
}

.closeButon {
    background-color: #30404a;
}

.filterMobile {
    border-color: #cbdce7;
}

.activeFilterMobile {
    background-color: #e4f3fb;
    color: #36a0d0;
}

.gradientBootom {
    background: linear-gradient(
        180deg,
        rgba(2, 0, 36, 0) 0%,
        rgba(42, 121, 9, 0) 50%,
        rgba(163, 192, 210, 0.5) 100%
    );
    color: rgba(163, 192, 210, 1);
}

.gradientTop {
    background: linear-gradient(
        0deg,
        rgba(2, 0, 36, 0) 0%,
        rgba(42, 121, 9, 0) 50%,
        rgba(163, 192, 210, 0.5) 100%
    );
    color: rgba(163, 192, 210, 1);
}

.grayColor {
    color: rgba(163, 192, 210, 1);
}

.buttonChangeTopic {
    background-color: #fff;
}

.buttonChangeTopicColor {
    // color: #678aa1;
}
