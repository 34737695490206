.wrapper {
    background-color: #e9f0f3 !important;
}

.title {
    color: #232f37;
}

.typePost {
    color: #678aa1;
    line-height: 140%;
}
