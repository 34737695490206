.root {
    border-radius: 16px;
    padding: 16px;
    display: flex;
    column-gap: 8px;
    align-items: center;
    background-color: #ffa722;
}
.warning {
    background-color: #ffa722;
}
.success {
    background-color: #71bd4e;
}
.error {
    background-color: #e55959;
}
.info {
    font-family: Inter;
    font-style: normal;
    line-height: normal;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.title {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}
.description {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
}
