.wrapper {
    background: rgba(233, 240, 243, 0.5);
}
.verticalLine {
    background: #d2dfe4;
}
.spanName {
    color: #678aa1;
}
.pName {
    color: #364954;
}
.buttonWrong {
    color: #55bbeb;
}
.input {
    border: 1px solid var(--Input-stroke, #d2dfe4);
    &:focus {
        border: 1px solid var(--Input-stroke-focus, #678aa1);
    }
}
