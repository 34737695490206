.button_info {
    border-radius: var(--radius-card, 16px);
    background: #55bbeb !important;
    box-shadow: 0px 4px 0px 0px #0b638c !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    display: block;
    padding: 14px 24px;
    transition: 0.2s;
    &_text {
        color: #fff;
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px; /* 133.333% */
        letter-spacing: 1px;
        text-transform: uppercase;
    }
    span {
        color: #fff;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        margin-inline-start: 28px;
        margin-inline-end: 28px;
    }
    &:hover {
        background: var(--background-accent-active, #36a0d0) !important;
        box-shadow: 0px 4px 0px 0px #08425c !important;
    }

    &.disable {
        background: var(--color-black-300, #a3c0d2);
        box-shadow: 0px 3.97241px 0px 0px #577487;
    }
    &:active {
        box-shadow: none !important;
        transform: translateY(4px);
    }
    box-shadow: 0px 4px 0px 0px #577487;
}

.activeButton {
    box-shadow: none !important;
    transform: translateY(4px);
}
.prevAndNextImg {
    border: 2px solid #e5e5e5;
    border-radius: 30%;
    height: fit-content;
}

.activityPagination {
    color: var(--color-black-400, #678aa1);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
}
.overlay_progress {
    position: absolute;
    z-index: 2050;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(31, 41, 55, 0.5);
}

.button_disabled {
    border-radius: var(--radius-card, 16px);
    background: #a3c0d2 !important;
    box-shadow: 0px 4px 0px 0px #577487;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    display: block;
    padding: 16px 24px;
    transition: 0.2s;
    padding: 14px 24px 14px;
    pointer-events: none;
    cursor: default;
}
.button_info {
    border-radius: var(--radius-card, 16px);
    background: #55bbeb !important;
    box-shadow: 0px 4px 0px 0px #0b638c !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    display: block;
    padding: 14px 24px;
    transition: 0.2s;
    &_text {
        color: #fff;
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px; /* 133.333% */
        letter-spacing: 1px;
        text-transform: uppercase;
    }
    span {
        color: #fff;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        margin-inline-start: 28px;
        margin-inline-end: 28px;
    }
    &:hover {
        background: var(--background-accent-active, #36a0d0) !important;
        box-shadow: 0px 4px 0px 0px #08425c !important;
    }
    &:active {
        box-shadow: none !important;
        transform: translateY(4px);
    }
    &.disable {
        background: var(--color-black-300, #a3c0d2);
        box-shadow: 0px 3.97241px 0px 0px #577487;
    }
    box-shadow: 0px 4px 0px 0px #577487;
}
.activeButton {
    box-shadow: none !important;
    transform: translateY(4px);
}
.paddingStar {
    padding: 3px;
}
.prevAndNextImg {
    border: 2px solid #e5e5e5;
    border-radius: 30%;
    height: fit-content;
}
.next {
    border: 2px solid #678aa1;
}

.activityPagination {
    color: var(--color-black-400, #678aa1);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
}
