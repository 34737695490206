.wrapper {
  border-radius: 16px;
  padding: 16px;
  background-color: #feefd4;
  display: flex;
  flex-direction: column;
  transition: height 0.3s ease-in-out;
  position: relative;
}
.root {
  display: flex;
  column-gap: 8px;
  align-items: flex-start;
  width: calc(100% - 24px);
}
.dropButton {
  align-self: flex-start;
  margin-inline-start: auto;
  border-radius: 10.667px;
  border: 2px solid var(--color-orange-300, #f3e1c0);
  box-sizing: content-box;
  background: #fff;
  cursor: pointer;
  &:hover {
    background: rgb(250, 244, 244);
  }
  position: absolute;
  top: 16px;
  right: 16px;

  &.arLng{
    right: auto;
    left: 16px;
  }
}
.button-success {
  border: 2px solid var(--color-orange-300, #e0f5d6);
}
.warning {
  background-color: #feefd4;
}
.success {
  background-color: #ecfce4;
}
.error {
  background-color: #f59393;
}
.info {
  color: #364954;
  font-family: Inter;
  font-style: normal;
  line-height: normal;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.title {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}
.description {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
