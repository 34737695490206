.wrapperSlider {
    height: 100%;
}

.slide {
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
}

.slide1, .slide2, .slide3{
    background: #bde1fc;
    overflow: hidden;
    &::after {
        content: '';
        display: block;
        position: absolute;
        border-radius: 571.043px;
        background: linear-gradient(140deg, #7e33fa 8.46%, #5aebbf 73.34%);
        filter: blur(100px);
        width: 571.043px;
        height: 571.043px;
        opacity: 0.3;
        top: 25%;
        left: 0;
    }
}

.subtitle {
    color: var(--text-text-secondary, #6d828e);
}
.forgetPasword {
    color: #55bbeb;
}

.enter {
    background: var(--surface-surface-primary, #e9f0f3);

    &:hover {
        background: #e9f0f3;
        color: #55bbeb;
    }
}

.ad {
    border: 1px solid var(--color-gray-200, #cad7df);
}

.footer {
    span {
        color: var(--text-text-tertiary, #9aaebb);
    }
}

.slideBlock {
    display: block;
}

@media screen and (max-width: 900px) {
    .slideBlock {
        display: none;
    }
}
.modalButton {
    background-color: #55bbeb;
    &:hover {
        background-color: #36a0d0;
    }
}
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}
.spinner {
    border: solid 3px #878c97;
    &:hover {
        border: solid 3px #55bbeb;
        background-color: transparent;
    }
    border-right-color: transparent;
    border-bottom-color: transparent;
    transition: all 0.5s ease-in;
    animation-name: rotate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
.validated.validated {
    background: #55bbeb;
    color: #fff;
}

.red{
    background-color: #F24F1C;
}

.green{
    background-color: #80BB00;
}

.blue{
    background-color: #00A5EF;
}

.yellow{
    background-color: #FFBA00;
}