.more{
    color: #fff;
    background-color: #55BBEB;
    box-shadow: 0px 3.972px 0px 0px #0B638C; 
    box-shadow: 0px 4px 0px 0px #0b638c !important;
    transition: 0.2s;
    &:hover {
        background: var(--background-accent-active, #36a0d0) !important;
        box-shadow: 0px 4px 0px 0px #08425c !important;
    }
    &:active {
        box-shadow: none !important;
        transform: translateY(4px);
    }
    box-shadow: 0px 4px 0px 0px #577487;
}