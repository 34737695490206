.wrapperTooltip.wrapperTooltip {
    background-color: #364954;
    color: #fff;
    border-radius: 12px;
    padding: 8px 12px;
    max-width: 320px;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    z-index: 200;
    @media screen and (max-width: 768px) {
        max-width: 197px;
    }
}

.tooltip.tooltip {
    background-color: #fff;
    border-radius: 8px;

    span {
        color: var(--color-black-500, #577487);
    }

    button {
        span {
            color: var(--color-black-main, #364954);
        }
        &:hover {
            background-color: #55bbeb;

            span {
                color: #fff;
            }
        }
    }
}

.dropdown {
    background-color: #fff;
    &_option {
        line-height: 17px;
        color: var(--color-black-main, #364954);
        &:hover {
            background-color: #eef9fd;
        }
        &:active > img {
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(125deg)
                brightness(103%) contrast(103%);
        }
        img {
            filter: invert(53%) sepia(16%) saturate(704%) hue-rotate(161deg)
                brightness(94%) contrast(89%);
        }
    }
}
.dropdown_option:active {
    background-color: #55bbeb;
    color: #fff;
}

.modal.modal {
    max-width: 592px;
    width: 100%;
}
.titleModal {
    color: #2e3138;
    line-height: normal;
}
.help.help {
    padding: 0;
    border-radius: 14px;
    z-index: 200;
}

.success {
    border: 2px solid var(--color-green-300, #cee8c2) !important;
    background: var(--color-green-100, #ecfce4) !important;
}
.fail {
    border: 2px solid var(--color-red-200, #f8dddd) !important;
    background: var(--color-red-100, #ffefef) !important;
}

.color-success {
    color: #65a845 !important;
}
.color-fail {
    color: #e55959 !important;
}

.title {
    color: #232f37;
    line-height: 24px;
    font-style: normal;
}

.type {
    color: #36a0d0;
    line-height: 24px;
}
