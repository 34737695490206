.wrapper {
    background-color: #fff;

    @media screen and (min-width: 640px) {
        // box-shadow: 0px 0px 34px 0px rgba(103, 138, 161, 0.2);
    }
}

.iconPreload {
    .arrowNext {
        background-image: url('../../../public/icons/arrowRightSlider.svg');
    }
    .arrowPrev {
        background-image: url('../../../public/icons/arrowLeftSlider.svg');
    }
    .iconClose {
        background-image: url('../../../public/icons/close.svg');
    }
}
