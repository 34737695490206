.root {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}
.tabButton {
    color: var(--text-tertiary, #678aa1);
    display: flex;
    padding: 7.5px 10px;
    align-items: flex-start;
    gap: 10px;
    font-style: normal;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 120%;
}
.activeTab {
    color: #364954;
    font-weight: 500;
    border-bottom: 2px solid var(--color-blue-main, #55bbeb);
}
