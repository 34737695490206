.rotate-180 {
    transform: rotate(180deg);
}

.grayText {
    color: #828792;
}

.addButton {
    background-color: #e9f0f3;
    color: #678aa1;
}

.bg-light {
    background-color: #e0f6ff;
}

.bg-blue {
    background-color: rgba(54, 160, 208, 0.1);
}

.bg-white {
    background-color: #fff;
}

.blueText {
    color: #55bbeb;
}

.blackText {
    color: #364954;
}

.arrowDown {
    border: 2px solid var(--color-black-400, #678aa1);
}
