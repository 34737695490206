.wrapperTooltip.wrapperTooltip {
    background-color: transparent;
    padding: 0;
}

.tooltip.tooltip {
    background-color: #fff;
    border-radius: 8px;

    span {
        color: var(--color-black-500, #577487);
    }

    button {
        span {
            color: var(--color-black-main, #364954);
        }
        &:hover {
            background-color: #eef9fd;
        }
        &:active {
            background-color: #55bbeb;

            span {
                color: #fff;
            }
        }
    }
}
