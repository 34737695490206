@import '../src/styles/style.scss';
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box;
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    color: #364954;
}

body {
    margin: 0;
    font-family: Inner, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.autorization {
    .swiper-pagination.swiper-pagination {
        max-width: 28rem;
        width: 100%;
        margin: 0 auto;
        display: flex;
        left: 50%;
        transform: translate(-50%, 0%);
        padding-bottom: 24px;

        .swiper-pagination-bullet-active {
            background-color: #fff;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: -3px;
                right: -3px;
                bottom: -3px;
                left: -3px;
                border: 1px solid #fff;
                border-radius: 50%;
            }
        }
    }
}

.sliderImagePost {
    .swiper-pagination.swiper-pagination {
        bottom: 0px;
        display: flex;
        span {
            flex-grow: 1;
            height: 4px;
            border-radius: 0;
            border-radius: 1px;

            &.swiper-pagination-bullet-active {
                background: var(--color-black-400, #678aa1);
            }
        }
    }
}

.sliderPopup {
    .swiper-slide-visible {
        &:not(.swiper-slide-thumb-active) {
            opacity: 0.2;
        }
    }

    .swiper-wrapper {
        align-items: center;
    }

    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
        @media screen and (max-width: 640px) {
            opacity: 0;
        }
    }

    .swiper-button-prev.swiper-button-prev,
    .swiper-button-next.swiper-button-next {
        width: 40px;
        height: 40px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2.667px solid var(--Stroke, #e3e5ea);
        border-radius: 50%;

        @media screen and (min-width: 640px) {
            width: 48px;
            height: 48px;
        }
    }
    .swiper-button-prev.swiper-button-prev {
        left: 0;
        z-index: 10;
        &::before {
            content: '';
            width: 24px;
            height: 24px;
            display: block;

            background-image: url('../public/icons/arrowLeftSlider.svg');
            background-size: 24px 24px;
            @media screen and (min-width: 640px) {
                background-size: 24px 24px;
                width: 24px;
                height: 24px;
            }
        }

        &::after {
            display: none;
        }

        @media screen and (max-width: 640px) {
            top: calc(100% + 16px + 48px);
        }
    }

    .swiper-button-next.swiper-button-next {
        right: 0;
        z-index: 10;
        &::before {
            content: '';
            width: 24px;
            height: 24px;
            display: block;
            background-image: url('../public/icons/arrowRightSlider.svg');
            background-size: 24px 24px;
            @media screen and (min-width: 640px) {
                background-size: 24px 24px;
                width: 24px;
                height: 24px;
            }
        }

        &::after {
            display: none;
        }

        @media screen and (max-width: 640px) {
            top: calc(100% + 16px + 48px);
        }
    }

    .swiper {
        overflow: unset;
        overflow-x: clip;
    }
}

[dir='rtl'] {
    .activity.activity {
        .swiper-button-prev {
            // right: calc(50% + 24px + 8px);
            left: auto;
            right: calc(50% - 24px - 8px - 28px);
            &::before {
                transform: rotate(0);
            }
        }

        .swiper-button-next {
            // right: calc(50% + 24px + 8px);
            left: auto;
            right: calc(50% + 24px + 8px);
            &::before {
                transform: rotate(180deg);
            }
        }
    }
    .imagePost.imagePost {
        .swiper-button-next::before {
            transform: rotate(180deg);
        }
        .swiper-button-prev::before {
            transform: rotate(0deg);
        }
    }

    .sliderPopup {
        .swiper-button-next.swiper-button-next {
            right: auto;

            left: 0;
        }

        .swiper-button-prev.swiper-button-prev {
            right: 0;
        }
        .swiper-button-next::before {
            transform: rotate(180deg);
        }
        .swiper-button-prev::before {
            transform: rotate(180deg);
        }
    }
}
// .slick-slider .slick-track, .slick-slider .slick-list { direction: rtl; }

.activity.activity {
    .swiper-pagination-fraction {
        width: fit-content;
        left: 50%;
        transform: translate(-50%);
    }

    .swiper-button-prev {
        position: absolute;
        top: calc(100% - 20px);
        left: calc(50% - 24px - 8px - 28px);
        &::after {
            display: none;
        }

        &::before {
            content: '';
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            position: relative;
            border-radius: 10.667px;
            border: 2px solid var(--color-black-200, #cbdce7);
            flex-shrink: 0;
            flex-grow: 0;
            background-image: url('../public/icons/buttonNext.svg');
            background-repeat: no-repeat;
            background-position: center;
            transform: rotate(180deg);
        }
    }

    .swiper-button-next {
        position: absolute;
        top: calc(100% - 16px);

        top: calc(100% - 20px);
        left: calc(50% + 24px + 8px);
        &::after {
            display: none;
        }

        &::before {
            content: '';
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            position: relative;
            border-radius: 10.667px;
            border: 2px solid var(--color-black-200, #cbdce7);
            flex-shrink: 0;
            flex-grow: 0;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url('../public/icons/buttonNext.svg');
        }
    }
}

.imagePost.imagePost {
    .swiper-pagination-fraction {
        width: fit-content;
        left: 50%;
        transform: translate(-50%);
    }

    .swiper-button-prev {
        position: absolute;
        // top: calc(100% - 20px);
        // left: calc(50% - 24px - 8px - 28px);

        &::after {
            display: none;
        }

        &::before {
            content: '';
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            position: relative;
            border-radius: 10.667px;
            border: 2px solid var(--color-black-200, #cbdce7);
            flex-shrink: 0;
            flex-grow: 0;
            background-image: url('../public/icons/buttonNext.svg');
            background-repeat: no-repeat;
            background-position: center;
            transform: rotate(180deg);
            background-color: #fff;
        }
    }

    .swiper-button-next {
        position: absolute;
        // top: calc(100% - 16px);

        // top: calc(100% - 20px);
        // left: calc(50% + 24px + 8px);
        &::after {
            display: none;
        }

        &::before {
            content: '';
            display: flex;
            background-color: #fff;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            position: relative;
            border-radius: 10.667px;
            border: 2px solid var(--color-black-200, #cbdce7);
            flex-shrink: 0;
            flex-grow: 0;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url('../public/icons/buttonNext.svg');
        }
    }
}

.slick-slider {
    position: relative;
    height: 100%;
    // overflow: hidden;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    height: 100%;
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus {
    outline: none;
}
.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    display: block;
}
.slick-track:before,
.slick-track:after {
    display: table;

    content: '';
}
.slick-track:after {
    clear: both;
}
.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
// [dir='rtl'] .slick-slide {
//     float: right;
// }
.slick-slide img {
    display: block;
}
.slick-slide.slick-loading img {
    display: none;
}
.slick-slide.dragging img {
    pointer-events: none;
}
.slick-initialized .slick-slide {
    display: block;

    & > div {
        height: 100%;
    }
}
.slick-loading .slick-slide {
    visibility: hidden;
}
.slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

.slick-disabled {
    filter: opacity(0.5);
}

.slick-arrow {
    width: 32px;
    height: 32px;
    position: absolute;
    top: calc(100% + 36px + 5%);
    background-color: #fff;
    z-index: 10;
    transform: translate(0, -50%);
    border-radius: 50%;
    border: 2.667px solid var(--Stroke, #e3e5ea);

    background-repeat: no-repeat;
    background-position: 50% 50%;
    cursor: pointer;

    &.slick-next {
        right: 4px;
        background-image: url('../public/icons/arrowRightSlider.svg');
        background-size: 16px;
    }
    &.slick-prev {
        left: 4px;
        background-image: url('../public/icons/arrowLeftSlider.svg');
        background-size: 16px;
    }
}

@media screen and (min-width: 640px) {
    .slick-arrow {
        width: 48px;
        height: 48px;
        top: 50%;

        &.slick-next {
            right: 8px;
            background-image: url('../public/icons/arrowRightSlider.svg');
            background-size: 24px;
        }
        &.slick-prev {
            left: 8px;
            background-image: url('../public/icons/arrowLeftSlider.svg');
            background-size: 24px;
        }
    }
}

.bottomGallery {
    .slick-slide {
        opacity: 0.5;
    }

    .slick-current {
        opacity: 1;
    }
}

[class='hidden']:checked + label.flex,
[class='hidden']:not(:checked) + label.flex {
    padding-right: 0;
    display: flex;
}

[class='hidden']:checked + label.flex::before,
[class='hidden']:checked + label.flex::after,
[class='hidden']:not(:checked) + label.flex::after,
[class='hidden']:not(:checked) + label.flex::before {
    content: none;
}

.editor-image {
    img {
        max-width: 100% !important;
    }
}
:root {
    --location-support-left: 'auto';
    --location-support-right: 'auto';
    @media screen and (max-width: 768px) {
        --isChatbot-visible: hidden;
    }
    --isChatbot-visible: visible;
}

.fc-widget-normal {
    visibility: var(--isChatbot-visible);
}

body {
    [type='checkbox']:checked + label:before,
    [type='checkbox']:not(:checked) + label:before,
    [type='checkbox']:not(:checked) + label:after,
    [type='checkbox']:checked + label,
    [type='checkbox']:not(:checked) + label {
        content: none;
        display: block;
        padding-right: 0px;
    }
}

body {
    select {
        padding: 0;
        margin: 0;
        max-width: none;
        color: inherit;
        font-size: inherit;
        font-family: Inter;
    }

    [type='checkbox']:checked + label:after,
    [type='checkbox']:not(:checked) + label:after {
        content: none;
    }
    [type='checkbox']:checked + label:after,
    [type='checkbox']:not(:checked) + label:after {
        background: none;
        position: static;
    }
}

#fc_frame,
#fc_frame.fc-widget-normal {
    @media screen and (max-width: 768px) {
        bottom: 70px !important;
    }
    bottom: 20px !important;
    z-index: 10000 !important;
    right: var(--location-support-right) !important;
    left: var(--location-support-left) !important;
}

.editor-readOnly {
    p {
        padding-inline-start: 0 !important;
    }
}

body {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE/Edge */
    user-select: none;

    form {
        -webkit-user-select: text; /* Safari */
        -moz-user-select: text; /* Firefox */
        -ms-user-select: text; /* IE/Edge */
        user-select: text;
    }

    button {
        -webkit-user-select: text; /* Safari */
        -moz-user-select: text; /* Firefox */
        -ms-user-select: text; /* IE/Edge */
        user-select: text;
    }
}

:root {
    container-name: pop;
    --visible-pop: false;
}

@container pop style(--visible-pop:true) {
    body {
        overflow: hidden !important;
        // position: fixed;
    }
}
