.wrapper {
    background-color: #e9f0f3 !important;
}

.resultTest {
    &__header {
        padding: 16px;
        h1 {
            color: #fff;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
        h2 {
            color: #fff;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }
    &_buttonDiasabled {
        border-radius: var(--radius-card, 16px);
        background: #a3c0d2 !important;
        box-shadow: 0px 4px 0px 0px #577487;
        color: #fff;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        display: block;
        padding: 16px 24px;
        transition: 0.2s;
        padding: 14px 24px 14px;
        cursor: default;
        width: 100%;
        &:active {
            box-shadow: none !important;
            transform: translateY(4px);
        }
    }
    &_buttonInfo {
        width: 100%;
        cursor: pointer;
        border-radius: var(--radius-card, 16px);
        background: #55bbeb !important;
        box-shadow: 0px 4px 0px 0px #0b638c !important;
        color: #fff;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        display: block;
        padding: 16px 24px;
        transition: 0.2s;
        padding: 14px 24px 14px;

        &:hover {
            background: var(--background-accent-active, #36a0d0) !important;
            box-shadow: 0px 4px 0px 0px #08425c !important;
        }
        &:active {
            box-shadow: none !important;
            transform: translateY(4px);
        }
    }
}
.customTitleStyles {
    color: var(--color-black-800, #232f37);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.customContentStyles {
    color: var(--color-black-main, #364954);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 8px;
}

.customFooterStyles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: wrap;
    width: 100%;
    line-break: anywhere;
    column-gap: 16px;
}
