.button-disabled {
    border-radius: var(--radius-card, 16px);
    background: #a3c0d2 !important;
    box-shadow: 0px 4px 0px 0px #577487;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    display: block;
    padding: 16px 24px;
    transition: 0.2s;
    padding: 14px 24px 14px;
    pointer-events: none;
    cursor: default;
    width: 100%;
}
.button-default {
    border-radius: var(--radius-card, 16px);
    background: #a3c0d2 !important;
    box-shadow: 0px 4px 0px 0px #577487;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    display: block;
    padding: 16px 24px;
    transition: 0.2s;
    padding: 14px 24px 14px;
    cursor: pointer;
    width: 100%;

    &:active {
        box-shadow: none !important;
        transform: translateY(4px);
    }
}
.button-info {
    width: 100%;
    cursor: pointer;
    border-radius: var(--radius-card, 16px);
    background: #55bbeb !important;
    box-shadow: 0px 4px 0px 0px #0b638c !important;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    display: block;
    padding: 16px 24px;
    transition: 0.2s;
    padding: 14px 24px 14px;

    &:hover {
        background: var(--background-accent-active, #36a0d0) !important;
        box-shadow: 0px 4px 0px 0px #08425c !important;
    }
    &:active {
        box-shadow: none !important;
        transform: translateY(4px);
    }

    &.disable {
        background: var(--color-black-300, #a3c0d2);
        box-shadow: 0px 3.97241px 0px 0px #577487;
    }
    box-shadow: 0px 4px 0px 0px #577487;
}
