.wrapper {
    background-color: #fff;

    label {
        .checked {
            background-color: #e9f0f3;
            justify-content: flex-start;
            &::before {
                border-radius: 50%;
                content: '';
                background-color: #a3c0d2;
                display: block;
                width: 16px;
                height: 16px;
            }
        }
    }

    input {
        &:checked {
            & + label {
                .checked {
                    justify-content: flex-end;
                    background-color: #55bbeb;
                    &::before {
                        background-color: #fff;
                    }
                }
            }
        }
    }
}

.hr {
    background-color: #e9f0f3;
}
.wrapperTooltip.wrapperTooltip {
    background-color: #364954;
    color: #fff;
    border-radius: 12px;
    padding: 8px 12px;
    max-width: 320px;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    z-index: 200;
}
