.option {
    border-color: #d2dfe4;

    .index {
        color: #678aa1;
    }

    .checked {
        // border-radius: 50%;
        border-color: #d2dfe4;
    }
}

.input {
    &:checked {
        & + .option {
            border-color: #55bbeb;
            background-color: #e4f3fb;

            .index {
                color: #55bbeb;
            }

            .checked {
                border-color: #55bbeb;
                background-image: url('../../../../../public/icons/answerChecked.svg');
            }
        }
    }
}

.answerFalse.answerFalse.answerFalse {
    &.option {
        border-color: #f59393;
        background-color: #ffefef;
    }

    .index {
        color: #e55959;
    }

    .checked {
        border-color: #e55959;
        background-image: url('../../../../../public/icons/answerFalse.svg');
    }
}

.answerTrue.answerTrue.answerTrue {
    &.option {
        border-color: #71bd4e;
        background-color: #ecfce4;
    }

    .index {
        color: #71bd4e;
    }
    .checked {
        border-color: #71bd4e;
        background-image: url('../../../../../public/icons/answerTrue.svg');
    }
}

.hint {
    background-color: #ddf4ff;
}

.labelHint {
    color: #55bbeb;
}

.hintCount {
    color: #55bbeb;
}

.hintButton {
    background-color: #e9f0f3;
    box-shadow: 0px 3.972px 0px 0px #bec3c6;

    &:hover:not(.hintButtonDisable) {
        background-color: #fff5e2;
        box-shadow: 0px 3.972px 0px 0px #fcaf3a;
        cursor: pointer;
    }
}

.checkAnswer {
    background: #55bbeb;
    box-shadow: 0px 3.972px 0px 0px #0b638c;
    color: #fff;
}

.checkAnswerDisable {
    background: var(--color-black-300, #a3c0d2);
    box-shadow: 0px 3.972px 0px 0px #577487;
    color: #fff;
}
.popularButton {
    color: #fff;
    background-color: #55bbeb;
    box-shadow: 0px 3.972px 0px 0px #0b638c;
    box-shadow: 0px 4px 0px 0px #0b638c !important;
    transition: 0.2s;
    &:hover {
        background: var(--background-accent-active, #36a0d0) !important;
        box-shadow: 0px 4px 0px 0px #08425c !important;
    }
    &:active {
        box-shadow: none !important;
        transform: translateY(4px);
    }

    &__disabled {
        background: #a3c0d2 !important;
        box-shadow: 0px 4px 0px 0px #577487 !important;
        cursor: default;
        &:active {
            transform: none;
            box-shadow: 0px 4px 0px 0px #577487 !important;
        }
        &:hover {
            background: #a3c0d2 !important;
            box-shadow: 0px 4px 0px 0px #577487 !important;
        }
    }
}
