.input {
    border: 1px solid var(--Input-stroke, #d2dfe4);
    &:focus {
        border: 1px solid var(--Input-stroke-focus, #678aa1);
    }
}

.word {
    color: #678aa1;
}
