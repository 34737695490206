.wrapper {
    background-color: #fff;
}

.section {
    color: #55bbeb;
}

.borderButton {
    border-color: #e3e5ea;
}

.wrapperTooltip.wrapperTooltip {
    background-color: #364954;
    color: #fff;
    border-radius: 12px;
    padding: 8px 12px;
    max-width: 320px;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    z-index: 200;
}
