/* ModalComponent.module.css */
.modal {
    background-color: rgba(0, 0, 0, 0.5);
}

.modalWrapper {
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
}

.button {
    color: #678aa1;
    background-color: #fff;
}

.title {
    line-height: 40px;
    color: #2e3138;
}
