.wrapper {
    z-index: 2;
    background-color: #fff;
    box-shadow: 0px 0px 34px 0px rgba(103, 138, 161, 0.2);
}

.title {
    color: var(--color-black-800, #232f37);
}

.subText {
    color: var(--color-black-400, #678aa1);
}

.button {
    border-color: var(--Stroke, #e3e5ea);
}
.wrapperTooltip.wrapperTooltip {
    background-color: #364954;
    color: #fff;
    border-radius: 12px;
    padding: 8px 12px;
    max-width: 350px;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    z-index: 200;
    @media screen and (max-width: 768px) {
        max-width: 280px;
    }
}
