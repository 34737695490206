.wrapper {
    border: 2px solid var(--Input-stroke, #d2dfe4);
    background: #fff;
    &:hover {
        border: 2px solid var(--Input-stroke-hover, #55bbeb);
    }
}
.wrapperSelected {
    border: 2px solid var(--Input-stroke-hover, #55bbeb);
    background-color: #e4f3fb;
}

.imgWrapper {
    background-color: #e9f0f3;
}
.imgWrapperSelected {
    background-color: #55bbeb;
}

.colorTitle {
    color: #364954;
}

.colorDescription {
    color: #678aa1;
}
.colorSelected {
    color: #55bbeb;
}
