.wrapper {
    background-color: #fff;
}

.hr {
    background: #e9f0f3;
}

.pagination {
    color: var(--color-black-400, #678aa1);
}
.wrapperTooltip.wrapperTooltip {
    background-color: #364954;
    color: #fff;
    z-index: 200;
    border-radius: 12px;
    padding: 8px 12px;
    max-width: 320px;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
