.questions {
    background: #fff;
}

.explanation {
    background: var(--color-green-100, #ecfce4);
    &_definition {
        color: #65a845 !important;
        line-height: 24px;
    }
    &_text {
        color: var(--color-black-main, #364954);
        line-height: 24px;
    }
}

.count {
    color: #55bbeb;
    line-height: normal;
}
.letter {
    color: var(--color-black-400, #678aa1);
    line-height: 24px;
}

.label {
    border: 2px solid #d2dfe4;
    color: var(--color-black-800, #232f37);
    line-height: 20px;
    background-color: #fff;
    transition: 0.2s;
    &:hover {
        background: #e9f0f3;
    }
    &::before {
        display: block;
        content: '';
        width: 24px;
        height: 24px;
        border: 2px solid #d2dfe4;
        border-radius: 50%;
        margin-inline-end: 12px;
        flex-shrink: 0;
    }
    &:hover::before {
        background: #fff;
    }
    &.error.error {
        border: 2px solid var(--color-red-400, #f59393);
        background: var(--color-red-100, #ffefef);

        &::before {
            border: 0px;
            background-color: #fff;
            background-position: 0px 0px;
            background-image: url('../../../public/icons/answerFalse.svg');
        }

        span {
            &:first-child {
                color: #e55959;
            }
        }
    }

    &.true.true {
        border: 2px solid var(--color-green-main, #71bd4e);
        background: var(--color-green-100, #ecfce4);

        &::before {
            border: 0px;
            background-color: #fff;
            background-position: 0px 0px;
            background-image: url('../../../public/icons/answerTrue.svg');
        }

        span {
            &:first-child {
                color: #71bd4e;
            }
        }
    }
}
.input {
    &:checked + label {
        border-radius: var(--All-radius, 12px);
        border: 2px solid var(--color-blue-main, #55bbeb);
        background: var(--color-blue-100, #e4f3fb);
        span {
            &:first-child {
                color: #55bbeb;
            }
        }
        &::before {
            border: 2px solid var(--color-blue-main, #55bbeb);
            background-color: #fff;
            background-image: url('../../../public/icons/answerChecked.svg');
            background-size: 24px 24px;
            background-repeat: no-repeat;
            background-position: -1px -1px;
        }
    }
}
