.overlay {
    position: fixed;
    z-index: 2001;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(31, 41, 55, 0.5);
    overflow-y: hidden;
    pointer-events: none;
}
.overlay_progress {
    position: absolute;
    z-index: 2050;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(31, 41, 55, 0.5);
    border-radius: 16px;
}
.modalOnBoarding {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(31, 41, 55, 0.5);
    z-index: 2002;
    footer {
        display: flex;
        gap: 16px;
        justify-content: center;
    }
    &__buttonSkip {
        color: #65a9c8;
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px; /* 133.333% */
        letter-spacing: 1px;
        text-transform: uppercase;
        background-color: #fff;
        margin-inline-end: 32px;
    }
    &__content {
        max-width: 400px;
        width: 100%;
        background-color: #fff;
        border-radius: 16px;
        padding: 24px;

        &__stages {
            color: var(--color-blue-main, #55bbeb);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 4px;
        }
        &__greetings {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 16px;
            gap: 16px;
            margin-bottom: 10.5px;
            margin-top: 4px;
            h1 {
                color: var(--color-black-800, #232f37);
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin: 0;
            }
        }
        &__description {
            margin: 0;
            color: var(--color-black-500, #577487);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 16px;
        }
    }
}

.button_info {
    border-radius: var(--radius-card, 16px);
    background: #55bbeb !important;
    box-shadow: 0px 4px 0px 0px #0b638c !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    display: block;
    padding: 14px 24px;
    transition: 0.2s;
    &_text {
        color: #fff;
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px; /* 133.333% */
        letter-spacing: 1px;
        text-transform: uppercase;
    }
    span {
        color: #fff;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        margin-inline-start: 28px;
        margin-inline-end: 28px;
    }
    &:hover {
        background: var(--background-accent-active, #36a0d0) !important;
        box-shadow: 0px 4px 0px 0px #08425c !important;
    }
    &:active {
        box-shadow: none !important;
        transform: translateY(4px);
    }
    box-shadow: 0px 4px 0px 0px #577487;
}

.activeButton {
    box-shadow: none !important;
    transform: translateY(4px);
}
.button_infoAss {
    width: fit-content;
    border-radius: var(--radius-card, 12px);
    background: #55bbeb !important;
    box-shadow: 0px 4px 0px 0px #0b638c !important;
    font-size: 14px;
    color: #fff;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    display: block;
    padding: 8px 12px;
    transition: 0.2s;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 133.333% */
    letter-spacing: 1px;
    text-transform: uppercase;

    &:hover {
        background: var(--background-accent-active, #36a0d0) !important;
        box-shadow: 0px 4px 0px 0px #08425c !important;
    }

    &.disable {
        background: var(--color-black-300, #a3c0d2);
        box-shadow: 0px 3.97241px 0px 0px #577487;
    }
    &:active {
        box-shadow: none !important;
        transform: translateY(4px);
    }
    box-shadow: 0px 4px 0px 0px #577487;
}

.activeButton {
    box-shadow: none !important;
    transform: translateY(4px);
}
.paddingStar {
    padding: 3px;
    background: #e9f0f3;
}

.wrapperSubjectFilter {
    background: rgb(233, 240, 243);
    background: linear-gradient(
        180deg,
        rgba(233, 240, 243, 1) 0%,
        rgba(233, 240, 243, 1) 83%,
        rgba(0, 212, 255, 0) 85%
    );
    padding-top: 12px;
    top: 56px;
}

.assessmentThem.assessmentThem.assessmentThem.assessmentThem {
    border-radius: 12px;
    border: 2px solid var(--color-blue-300, #d1eefd);
    background: var(--color-blue-100, #e4f3fb);
    display: flex;
    padding: 8px 16px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.assessmentTitle.assessmentTitle.assessmentTitle.assessmentTitle.assessmentTitle {
    color: var(--Gray-2, #4f4f4f);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
}
.assessmentDescription {
    color: var(--color-black-500, #577487);
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
}
.descriptionSuccess {
    color: var(--color-green-800, #65a845);
    font-weight: 600;
}
.descriptionFail {
    color: var(--color-red-500, #e55959);
    font-weight: 600;
}

@keyframes moveUp {
    0% {
        transform: translateY(600px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.videoMoveUpAnimation {
    animation: moveUp 0.7s ease-in-out forwards;
}
