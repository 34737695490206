.passingTest {
    background: #fff;
}

.count {
    color: var(--color-blue-main, #55bbeb);
    line-height: normal;
}

.letter {
    color: var(--color-black-400, #678aa1);
    line-height: 24px; /* 150% */
}
.input {
    &:checked + label {
        border-radius: var(--All-radius, 12px);
        border: 2px solid var(--color-blue-main, #55bbeb);
        background: var(--color-blue-100, #e4f3fb);

        span {
            &:first-child {
                color: #55bbeb;
            }
        }

        &::before {
            border: 2px solid var(--color-blue-main, #55bbeb);
            background-color: #fff;
            background-image: url('../../../../public/icons/answerChecked.svg');
            background-size: 24px 24px;
            background-repeat: no-repeat;
            background-position: -1px -1px;
        }
    }
}
.label {
    border: 2px solid #d2dfe4;
    color: var(--color-black-800, #232f37);
    line-height: 20px;
    background-color: #fff;
    transition: 0.2s;
    &::before {
        display: block;
        content: '';
        width: 24px;
        height: 24px;
        border: 2px solid #d2dfe4;
        border-radius: 50%;
        margin-inline-end: 12px;
        flex-shrink: 0;
    }
    &:hover::before {
        background: #fff;
    }
}
.footer {
    button:nth-child(1)[style*='display: none;'] + button {
        margin-inline-start: auto;
        width: fit-content;
        flex-grow: 0;
    }
    &__button {
        color: #fff;
        line-height: 16px;
        transition: 0.2s;
        &:active {
            box-shadow: none !important;
            transform: translateY(4px);
        }
        &:disabled {
            background: #a3c0d2;
            box-shadow: 0px 4px 0px 0px #577487;
            cursor: default;
        }
        &__simple {
            background: #55bbeb;
            box-shadow: 0px 4px 0px 0px #0b638c;
            &:hover {
                background: #36a0d0;
            }
        }
        &__success {
            background: var(--color-green-600, #7fc261);
            box-shadow: 0px 4px 0px 0px #3d6b28;
            &:hover {
                background: var(--color-green-500, #65a845);
                box-shadow: 0px 4px 0px 0px #2c4c1d;
            }
        }
    }
}

.modal {
    &__header {
        color: var(--color-black-800, #232f37);
        line-height: normal;
    }
    &__text {
        color: var(--color-black-main, #364954);
    }
}

.button_disabled {
    background: #a3c0d2 !important;
    box-shadow: 0px 4px 0px 0px #577487;
    color: #fff;
    line-height: 16px;
    transition: 0.2s;
    &:active {
        box-shadow: none !important;
        transform: translateY(4px);
    }
}
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

.spinner {
    border: solid 3px #878c97;
    &:hover {
        border: solid 3px #55bbeb;
        background-color: transparent;
    }
    border-right-color: transparent;
    border-bottom-color: transparent;
    transition: all 0.5s ease-in;
    animation-name: rotate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
