.option {
    border-color: #fff;
    background-color: #fff;
    .index {
        color: #678aa1;
    }

    .checked {
        // border-radius: 50%;
        border-color: #d2dfe4;
    }
}

.input {
    &:checked {
        & + .option {
            border-color: #55bbeb;
            background-color: #e4f3fb;

            .index {
                color: #55bbeb;
            }

            .checked {
                border-color: #55bbeb;
                background-image: url('../../../../../public/icons/answerChecked.svg');
            }
        }
    }
}

.answerFalse.answerFalse.answerFalse {
    &.option {
        border-color: #f59393;
        background-color: #ffefef;
    }

    .index {
        color: #e55959;
    }

    .checked {
        border-color: #e55959;
        background-image: url('../../../../../public/icons/answerFalse.svg');
    }
}

.answerTrue.answerTrue.answerTrue {
    &.option {
        border-color: #71bd4e;
        background-color: #ecfce4;
    }

    .index {
        color: #71bd4e;
    }
    .checked {
        border-color: #71bd4e;
        background-image: url('../../../../../public/icons/answerTrue.svg');
    }
}
