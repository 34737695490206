.popularButton {
    transition: 0.2s;
    &:active {
        box-shadow: none !important;
        transform: translateY(4px);
    }
    &__disabled {
        background: #a3c0d2 !important;
        box-shadow: 0px 4px 0px 0px #577487 !important;
        cursor: default;
        &:active {
            transform: none;
            box-shadow: 0px 4px 0px 0px #577487 !important;
        }
    }
    &__simple {
        background: #55bbeb;
        box-shadow: 0px 4px 0px 0px #0b638c;
        &:hover {
            background: #36a0d0;
        }
    }
    &__success {
        background: var(--color-green-600, #7fc261);
        box-shadow: 0px 4px 0px 0px #3d6b28;
        &:hover {
            background: var(--color-green-500, #65a845);
            box-shadow: 0px 4px 0px 0px #2c4c1d;
        }
    }
}
