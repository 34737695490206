.wrapper {
    background-color: #fff;
}

.more {
    color: #55bbeb;
}

.shadow {
    background: linear-gradient(
        180deg,
        rgba(2, 0, 36, 0) 0%,
        rgba(42, 121, 9, 0) 50%,
        rgba(255, 255, 255, 1) 100%
    );
}

.content {
    background-color: #fff;
    font-family: Inter, sans-serif !important;
    div div div div p {
        text-indent: 0em !important;
        font-family: Inter, sans-serif !important;
        color: black;
    }
    div div div div p span img {
        display: inline-block !important;
    }

    div div div div p span div {
        display: inline !important;
        font-family: Inter, sans-serif !important;
        color: black;
    }
    div div div div p span {
        font-family: Inter, sans-serif !important;
        color: black;
    }
    div div div div p strong {
        font-family: Inter, sans-serif !important;
        color: black;
    }

    div div div th p {
        text-indent: 0 !important;
        font-family: Inter, sans-serif !important;
        color: black;
    }
    div div div td p {
        text-indent: 0 !important;
        font-family: Inter, sans-serif !important;
        color: black;
    }
    div div div div ol li span div {
        width: fit-content !important;
        display: inline !important;
    }
}
