.forType {
    color: #678aa1;
    line-height: 140%;
}
.typeTitle {
    color: #232f37;
    line-height: normal;
}
.icon {
    border: 2.5px solid #e3e5ea;
    &:hover {
        background: #f2f6f8;
    }
}

.cancel {
    border: 2px solid var(--color-black-400, #678aa1);
}
