.input {
    border: 1px solid var(--Input-stroke, #d2dfe4);
    &:focus {
        border: 1px solid var(--Input-stroke-focus, #678aa1);
    }
}
.word {
    color: #678aa1;
}
.checkboxText {
    color: #364954;
}
.awaiting {
    color: #678aa1;
}
.confirmed {
    color: #55bbeb;
}
.typeFilter {
    input:checked {
        & + label {
            span {
                border-color: #55bbeb;
                background-image: url('../../../../public/icons/checkboxActive.svg');
            }
        }
    }

    label {
        span {
            border-color: #e3e5ea;
        }
    }
}
