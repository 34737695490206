.bgWhite {
    background-color: #fff !important;
}
.metricWrapper {
    border: 2px solid #cbdce7;
}
.time {
    color: #678aa1;
    line-height: 14px;
}
.minutes {
    color: #364954;
    line-height: 24px;
}

.color-success {
    color: #65a845 !important;
}
.color-fail {
    color: #e55959 !important;
}
