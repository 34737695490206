@keyframes fadeInAndOut {
    0% {
        opacity: 0;
        transform: scale(0.6);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
.tooltip {
    z-index: 10000 !important;
    max-width: 272px !important;
    @media screen and (max-width: 1255px) {
        max-width: 202px !important;
    }
    background: #fff !important;
    border-radius: 16px !important;
    padding: 16px !important;
    transition:
        opacity 0.3s ease-in-out,
        transform 0.3s ease-in-out !important;
    animation: fadeInAndOut 0.5s forwards !important;
}

.button {
    color: var(--color-black-300, #a3c0d2);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    cursor: pointer;
    font-weight: 600;
    line-height: 16px;
    background-color: #fff;
}
